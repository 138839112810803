<template>
  <section>
    <div class="grid md:grid-9-3 ">
      <div class="h-65vh position-relative">

        <transition name="fade" v-for="(motor,i) in motors">
          <div style="background-size: contain; overflow: hidden; background-repeat: no-repeat; position: absolute; z-index:1; top:0; " :style="{backgroundImage:'url('+orgamaxImage(motor)+')'}"  v-if="motor.id === selected.id" class="w-100p h-100p">
          </div>
        </transition>
      </div>
      <div class="m-l-2 p-3 d-flex f-column md:h-65vh">
        <selection-header :back-to="'/modules'" :steps="6" :current-step="5"/>
        <total-runner :step="5" class="f-1"/>
        <bottom-nav to="/supply" back-to="/modules"/>
      </div>
    </div>
    <tab-shop v-if="motors.length>0" :item="selected" selected-tab="motor" :multi-items="{motor:motors}" @update="selected = $event.item">
      Select your driving unit
    </tab-shop>
  </section>

</template>

<script>
import api from "@/api";
import selectionHeader from "@/components/selectionHeader";
import totalRunner from "@/components/totalRunner"
import Shop from "@/components/Shop"
import {orgamaxImage} from "@/composables/orgamax";
import TabShop from "@/components/TabShop";
import BottomNav from "@/components/BottomNav";

export default {
  name: "Driving",
  components:{selectionHeader, TabShop, BottomNav, totalRunner},
  data:()=>({
    motors:[],
    active:0,
    selected: null
  }),
  watch:{
    selected(newV){
      if(newV){
        this.$store.commit('setMotor', newV)
        this.apply(newV)
      }
    }
  },
  async mounted() {
    try{

      this.motors = this.$store.getters.motors;
      this.selected =  typeof this.$store.state.motor.id !== 'undefined' ? this.$store.state.motor : this.motors[0]
      this.apply(this.selected)
    } catch (e) {
      console.log(e)
    }

  },
  methods: {
    orgamaxImage,
    apply(module){
      this.$store.commit('setMotor', module)
    }
  }
}
</script>

<style scoped>

</style>